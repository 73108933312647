import React, {
  createContext,
  type RefObject,
  useContext,
  useRef,
} from 'react';
import { IntercomProvider } from 'react-use-intercom';
import {
  ChakraProvider,
  useDisclosure,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';

import Footer from '@components/Footer';
import Sidebar from '@components/Sidebar';
import type { SanityShared } from '@lib/integrations/sanity';
import type {
  CommonDocument,
  FormsDocument,
  ToastsDocument,
} from '@raise/sanity';
import theme, { toastOptions } from '@raise/theme';

import BingAnalytics from './components/BingAnalytics';
import FacebookAnalytics from './components/FacebookAnalytics';
import FontAwesome from './components/FontAwesome';
import GoogleAnalytics from './components/GoogleAnalytics';
import Head, { type HeadProps } from './components/Head';

// Define the types for the app layout context
type AppLayoutContextType = {
  sidebarDisclosure: UseDisclosureReturn;
  addNewButtonRef: RefObject<HTMLButtonElement>;
  common: CommonDocument;
  forms: FormsDocument;
  toasts: ToastsDocument;
};

// Define the context for the app layout
const AppLayoutContext = createContext({});
export const useAppLayoutContext = () =>
  useContext<Partial<AppLayoutContextType>>(AppLayoutContext);

type Props = {
  children?: React.ReactNode;
  meta?: HeadProps;
  shared: SanityShared;
};

const AppLayout: React.FC<Props> = ({ children, meta, shared }) => {
  const [common, forms, toasts] = shared;
  const { footer, app } = common;

  const sidebarDisclosure = useDisclosure();
  const addNewButtonRef = useRef(null);

  return (
    <IntercomProvider
      appId={process.env.NEXT_PUBLIC_INTERCOM_APPID ?? ''}
      autoBoot
    >
      <AppLayoutContext.Provider
        value={{ common, forms, toasts, sidebarDisclosure, addNewButtonRef }}
      >
        <Head {...(meta ?? common.meta)} />
        <FontAwesome />
        <VercelAnalytics />
        <GoogleAnalytics />
        <BingAnalytics />
        <FacebookAnalytics />
        <ChakraProvider theme={theme} toastOptions={toastOptions}>
          <Sidebar links={app} disclosure={sidebarDisclosure}>
            {children}
          </Sidebar>
          <Footer {...footer} />
        </ChakraProvider>
      </AppLayoutContext.Provider>
    </IntercomProvider>
  );
};

export default AppLayout;
