import Script from 'next/script';

const FacebookAnalytics = () => (
  <>
    <Script
      src="https://tag.brandcdn.com/autoscript/raise_vfhwcmvrnvvuvda9/RAISE.js"
      strategy="afterInteractive"
    />
  </>
);

export default FacebookAnalytics;
