import React from 'react';
import { Box, Flex, HStack, Link, Stack, Text } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';

import { SanityIcon } from '@components/Icon';
import { pageContainerProps } from '@components/PageContainer';
import type { CommonDocument } from '@raise/sanity';

const Footer = ({
  social,
  legal,
  copyright,
  phone,
  address,
  terms,
  policy,
  contact,
}: CommonDocument['footer']) => (
  <Box bgGradient="linear(to-b, gray.800, gray.900)" py={16}>
    <Flex
      {...pageContainerProps}
      direction={['column', null, 'row']}
      justifyContent="space-between"
      alignItems="center"
    >
      <HStack spacing={6} order={{ md: 2 }}>
        {social &&
          social.map((item) =>
            item ? (
              <Link
                key={item.link}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                color="gray.400"
                _hover={{ color: 'gray.200' }}
              >
                <SanityIcon {...item.icon} size="xl" />
              </Link>
            ) : null,
          )}
      </HStack>
      <Text
        textAlign="center"
        color="gray.400"
        mt={[8, null, 0]}
        order={{ md: 1 }}
      >
        &copy; {new Date().getFullYear()} {copyright}
      </Text>
    </Flex>
    <Box
      {...pageContainerProps}
      textAlign={['center', null, 'left']}
      mt={1}
      mb={4}
      color="gray.500"
    >
      <Text>
        {phone}&nbsp;&nbsp;|&nbsp;&nbsp;{address}
      </Text>
    </Box>
    <Stack
      {...pageContainerProps}
      spacing={[2, 6]}
      mt={3}
      direction={['column', 'row']}
      justifyContent={['center', null, 'flex-start']}
      alignItems="center"
    >
      <Link
        fontWeight="semibold"
        color="gray.400"
        _hover={{ color: 'gray.200', textDecoration: 'none' }}
        href="/terms"
      >
        {terms}
      </Link>
      <Link
        fontWeight="semibold"
        color="gray.400"
        _hover={{ color: 'gray.200', textDecoration: 'none' }}
        href="/policy"
      >
        {policy}
      </Link>
      <Link
        fontWeight="semibold"
        color="gray.400"
        _hover={{ color: 'gray.200', textDecoration: 'none' }}
        href="mailto:contact@raisefinancial.com"
      >
        {contact}
      </Link>
    </Stack>
    <Box maxW="7xl" mx="auto" pt={12} px={[4, 6]}>
      <PortableText
        value={legal}
        components={{
          block: {
            normal: ({ children }) => (
              <Text color="gray.600" mt={3}>
                {children}
              </Text>
            ),
          },
        }}
      />
    </Box>
  </Box>
);

export default Footer;
