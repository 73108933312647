import { Flex, IconButton, type UseDisclosureReturn } from '@chakra-ui/react';

import Icon from '@components/Icon';
import UserAvatar from '@components/UserAvatar';

import { Logo } from './Header';

type MobileHeaderProps = {
  disclosure: UseDisclosureReturn;
};

const MobileHeader: React.FC<MobileHeaderProps> = ({ disclosure }) => (
  <Flex justify="space-between" align="center" px={6} py={4} bg="white">
    <IconButton
      aria-label="Menu"
      variant="ghost"
      colorScheme="gray"
      icon={
        <Icon
          style="solid"
          icon={disclosure.isOpen ? 'times' : 'bars'}
          size="lg"
        />
      }
      onClick={disclosure.onOpen}
    />
    <Logo />
    <UserAvatar />
  </Flex>
);

export default MobileHeader;
