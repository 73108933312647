import React, { createContext, useContext } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import {
  ChakraProvider,
  Grid,
  useDisclosure,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import dynamic from 'next/dynamic';

import Footer from '@components/Footer';
import type { SanityShared } from '@lib/integrations/sanity';
import type {
  CommonDocument,
  FormsDocument,
  MetaSharedObject,
  ToastsDocument,
} from '@raise/sanity';
import theme, { toastOptions } from '@raise/theme';

import BingAnalytics from './components/BingAnalytics';
import FacebookAnalytics from './components/FacebookAnalytics';
import FontAwesome from './components/FontAwesome';
import GoogleAnalytics from './components/GoogleAnalytics';
import Head, { type HeadProps } from './components/Head';
import VideoDialog from './components/VideoDialog';
import WaitlistDialog from './components/WaitlistDialog';

// Since the header depends on window, we get hydration errors when rendering it on the server
// So, only load the Header only on the client
const Header = dynamic(() => import('../Header'), {
  ssr: false,
});

// Define the types for the layout context
type MainLayoutContextType = {
  waitlistDialog: UseDisclosureReturn;
  videoDialog: UseDisclosureReturn;
  common: CommonDocument;
  forms: FormsDocument;
  toasts: ToastsDocument;
};

// Define the context for the main layout
const MainLayoutContext = createContext({});
export const useMainLayoutContext = () =>
  useContext<Partial<MainLayoutContextType>>(MainLayoutContext);

type Props = {
  children?: React.ReactNode;
  meta?: HeadProps;
  shared: SanityShared;
};

const MainLayout: React.FC<Props> = ({ children, meta, shared }) => {
  const [common, forms, toasts] = shared;
  const { header, footer } = common;

  const waitlistDialog = useDisclosure();
  const videoDialog = useDisclosure();

  return (
    <IntercomProvider
      appId={process.env.NEXT_PUBLIC_INTERCOM_APPID ?? ''}
      autoBoot
    >
      <MainLayoutContext.Provider
        value={{ waitlistDialog, videoDialog, common, forms, toasts }}
      >
        <Head {...(meta ?? common.meta)} />
        <FontAwesome />
        <VercelAnalytics />
        <GoogleAnalytics />
        <BingAnalytics />
        <FacebookAnalytics />
        <ChakraProvider theme={theme} toastOptions={toastOptions}>
          <Grid minH="100vh" templateRows="auto 1fr auto">
            <Header links={header} />
            {children}
            <Footer {...footer} />
          </Grid>
          <WaitlistDialog
            dialog={waitlistDialog}
            common={common.common}
            forms={forms!}
          />
          <VideoDialog dialog={videoDialog} id={common.common.video_id} />
        </ChakraProvider>
      </MainLayoutContext.Provider>
    </IntercomProvider>
  );
};

export default MainLayout;
