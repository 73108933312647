import type {
  CommonDocument,
  FormsDocument,
  ToastsDocument,
} from '@raise/sanity';

import { client } from './client';

export const noDraft = `!(_id in path('drafts.**'))`;
export const locale = `language == $locale && ${noDraft}`;
export const defaultLocale = `language == $defaultLocale && ${noDraft}`;

export const coalesce = (document: string, extra?: string) => `coalesce(
  *[_type == "${document}" && ${locale} ${extra || ''}][0],
  *[_type == "${document}" && ${defaultLocale} ${extra || ''}][0]
)`;

export type SanityShared = [CommonDocument, FormsDocument, ToastsDocument];

export const getSanityShared = async (props: any): Promise<SanityShared> =>
  // @ts-ignore Sanity-typed can't generate types like this
  await client.fetch(
    `[
  ${coalesce('common')},
  ${coalesce('forms')},
  ${coalesce('toasts')},
]`,
    props,
  );
