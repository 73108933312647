import { useEffect, useState } from 'react';

import { isSSR } from '.';

type ScrollState = {
  scrollX: number | null;
  scrollY: number | null;
};

const getScroll = (): ScrollState => ({
  scrollX: isSSR ? null : window.pageXOffset || window.scrollX,
  scrollY: isSSR
    ? null
    : window.pageYOffset || document.documentElement.scrollTop,
});

export const useScroll = (): ScrollState => {
  const [scrollState, setScroll] = useState(getScroll());

  useEffect(() => {
    let ticking: number | null = null;

    const handleScroll = () => {
      if (!ticking) {
        ticking = window.requestAnimationFrame(() => {
          setScroll(getScroll());
          ticking = null;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollState;
};
