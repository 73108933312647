import { useAppLayoutContext } from './AppLayout';
import { useMainLayoutContext } from './MainLayout';

export const useLayoutContext = () => {
  const appContext = useAppLayoutContext();
  const mainContext = useMainLayoutContext();

  return Object.keys(appContext).length !== 0 ? appContext : mainContext;
};

export { default as AppLayout } from './AppLayout';
export { default as MainLayout } from './MainLayout';
