import {
  type ToastId,
  useToast as useChakraToast,
  type UseToastOptions,
} from '@chakra-ui/react';

import type { ToastSharedObject } from '@raise/sanity';

// Add Intercom to global window object
declare global {
  interface Window {
    Intercom: (event: string) => void;
  }
}

type UseToastReturn = {
  success: (opts?: UseToastOptions) => ToastId;
  error: (opts?: UseToastOptions) => ToastId;
};

const DEFAULT_CONTENT = {
  success: { title: 'Success', description: 'Success' },
  error: { title: 'Error', description: 'Error' },
};

const useToast = (
  content: Pick<ToastSharedObject, 'success' | 'error'> = DEFAULT_CONTENT,
): UseToastReturn => {
  const toast = useChakraToast({
    isClosable: true,
    duration: 7000,
  });

  return {
    success: (options = {}) => {
      return toast({
        title: content.success.title,
        description: content.success.description,
        status: 'success',
        ...options,
      });
    },
    error: (options = {}) => {
      const t = toast({
        title: content.error.title,
        description: content.error.description,
        status: 'error',
        ...options,
      });

      if (content.error.description.includes('support')) {
        setTimeout(() => {
          window.Intercom('show');
        }, 1000);
      }

      return t;
    },
  };
};

export default useToast;
