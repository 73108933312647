import NextHead from 'next/head';

import { sanityImage } from '@lib/integrations/sanity';
import type { MetaSharedObject } from '@raise/sanity';

export type HeadProps = Partial<MetaSharedObject> & {
  image?: MetaSharedObject['image'] | string;
};

const POST_TITLE = 'Raise Financial';
const SEPARATOR = ' | ';

const Head = ({ title = '', description, image, link }: HeadProps) => {
  // Get image URL
  const imageUrl = typeof image === 'string' ? image : sanityImage(image).url();

  // Append post title to page title
  title = title ? `${title}${SEPARATOR}${POST_TITLE}` : POST_TITLE;

  return (
    <NextHead>
      {/* Title and description */}
      <title>{title}</title>
      <meta name="description" key="description" content={description} />

      {/* OpenGraph (Facebook/Meta) */}
      <meta property="og:type" key="og:type" content="website" />
      <meta property="og:url" key="og:url" content={link} />
      <meta property="og:title" key="og:title" content={title} />
      <meta
        property="og:description"
        key="og:description"
        content={description}
      />
      <meta property="og:image" key="og:image" content={imageUrl} />

      {/* Twitter */}
      <meta
        property="twitter:card"
        key="twitter:card"
        content="summary_large_image"
      />
      <meta property="twitter:url" key="twitter:url" content={link} />
      <meta property="twitter:title" key="twitter:title" content={title} />
      <meta
        property="twitter:description"
        key="twitter:description"
        content={description}
      />
      <meta property="twitter:image" key="twitter:image" content={imageUrl} />

      {/* Browser and theming */}
      <meta
        name="apple-mobile-web-app-title"
        key="apple-mobile-web-app-title"
        content={title}
      />
      <meta name="application-name" key="application-name" content={title} />
      <meta
        name="msapplication-TileColor"
        key="msapplication-TileColor"
        content="#1f2937"
      />
      <meta name="theme-color" key="theme-color" content="#1f2937" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ec4899" />
    </NextHead>
  );
};

export default Head;
