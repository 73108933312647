import Script from 'next/script';

const FontAwesome = () => (
  <Script
    src="https://kit.fontawesome.com/f9584b53ca.js"
    crossOrigin="anonymous"
    strategy="afterInteractive"
  />
);

export default FontAwesome;
