import { Image, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

import logoLight from '@assets/images/raise-logo-light.svg';

export const Logo = () => (
  <Link as={NextLink} href="/app" variant="unstyled">
    <Image alt="Raise Financial" h={8} src={logoLight.src} />
  </Link>
);
