import {
  extendTheme,
  withDefaultColorScheme,
  type ToastProviderProps,
} from '@chakra-ui/react';

import { colors, brandedColors } from './colors';
import { components } from './components';
import { fonts } from './typography';

import 'focus-visible/dist/focus-visible';

const theme = extendTheme(
  {
    styles: {
      global: {
        [`.js-focus-visible :focus:not([data-focus-visible-added])`]: {
          outline: 'none',
          boxShadow: 'none',
        },
        [`#t-modal.t-modal`]: {
          zIndex: `3000 !important`,
        },
      },
    },
    colors: {
      ...colors,
      ...brandedColors,
    },
    components,
    fonts,
  },
  withDefaultColorScheme({ colorScheme: 'brand' }),
);

export default theme;

export const toastOptions: ToastProviderProps = {
  defaultOptions: { position: 'bottom-left', variant: 'left-accent' },
};
