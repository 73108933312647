import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import {
  Box,
  Flex,
  type FlexProps,
  Hide,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  type TextProps,
  VStack,
} from '@chakra-ui/react';
import { useUser } from '@contexts/user';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import Icon, {
  getIconByType,
  getIconColorByType,
  SanityIcon,
} from '@components/Icon';
import { useLayoutContext } from '@components/Layout';
import LoadingSpinner from '@components/LoadingSpinner';
import { getNameOfAccount } from '@raise/common';
import type { CommonDocument, IconsSharedObject } from '@raise/sanity';

type SidebarMenuProps = {
  links: CommonDocument['app'];
} & FlexProps;

type SidebarMenuItemProps = {
  active?: boolean;
  subtle?: boolean;
  name: string;
  icon: IconsSharedObject;
  colors?: {
    icon: string;
    hover: string;
    active: string;
  };
  href?: string;
  onClick?: () => void;
};

type SidebarMenuItemWrapperProps = Pick<
  SidebarMenuItemProps,
  'href' | 'onClick'
> & {
  children: React.ReactNode;
};

const SidebarMenuItemWrapper: React.FC<SidebarMenuItemWrapperProps> = ({
  href,
  onClick,
  children,
}) => {
  if (href) {
    return (
      <Link as={NextLink} href={href} variant="unstyled" w="full">
        {children}
      </Link>
    );
  } else if (onClick) {
    return (
      <Box w="full" onClick={onClick}>
        {children}
      </Box>
    );
  }

  return <Box w="full">{children}</Box>;
};

const SidebarMenuItem = ({
  active,
  name,
  subtle,
  icon,
  colors,
  href,
  onClick,
}: SidebarMenuItemProps) => (
  <SidebarMenuItemWrapper href={href} onClick={onClick}>
    <HStack
      w="full"
      px={3}
      py={2}
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.25s"
      spacing={3}
      bg={active ? (colors ? colors.active : 'gray.200') : undefined}
      _hover={{
        bg: !active ? (colors ? colors.hover : 'gray.100') : undefined,
      }}
    >
      <Flex w={6} h={6} justifyContent="center" alignItems="center">
        <SanityIcon
          {...icon}
          size="lg"
          color={colors ? colors.icon : active ? 'currentcolor' : 'gray.400'}
        />
      </Flex>
      <Text
        fontWeight="semibold"
        color={active ? 'black' : subtle ? 'gray.400' : undefined}
      >
        {name}
      </Text>
    </HStack>
  </SidebarMenuItemWrapper>
);

const Label: React.FC<TextProps> = (props) => (
  <Text
    fontSize="xs"
    fontWeight="bold"
    textTransform="uppercase"
    letterSpacing="widest"
    color="gray.400"
    mb={2}
    {...props}
  />
);

const linkProps = (href: string, path: string) => ({
  href,
  active: href === path,
});

const SidebarMenu: React.FC<SidebarMenuProps> = ({ links, ...props }) => {
  const context = useLayoutContext();

  const router = useRouter();
  const intercom = useIntercom();

  const { accounts, isAccountsLoading } = useUser();

  const getFunction = useCallback(
    (fn?: string) => {
      if (fn === 'intercom') {
        return intercom.show;
      }

      return undefined;
    },
    [intercom.show],
  );

  if (!('addNewButtonRef' in context)) return null;
  const addNewButtonRef = context?.addNewButtonRef;

  const type = 'education';

  return (
    <Flex flex={1} justifyContent="space-between" direction="column" {...props}>
      <Box>
        <Label>{links.accounts.accounts}</Label>
        <VStack spacing={2}>
          {isAccountsLoading && <LoadingSpinner />}
          {accounts.map((account) => (
            <SidebarMenuItem
              key={account.id}
              name={getNameOfAccount(account)}
              {...account}
              {...linkProps(`/app/education/${account.hashid}`, router.asPath)}
              icon={getIconByType(type)}
              colors={getIconColorByType(type)}
            />
          ))}
          <Menu>
            {!isAccountsLoading && (
              <MenuButton
                ref={addNewButtonRef}
                w="full"
                px={3}
                py={2}
                cursor="pointer"
                userSelect="none"
                rounded="md"
                color="gray.800"
                textAlign="left"
                fontWeight="semibold"
                _hover={{
                  bg: 'gray.100',
                }}
              >
                <Icon
                  style="duotone"
                  icon="rectangle-history-circle-plus"
                  size="lg"
                  color="gray.400"
                  mr={3}
                />
                {links.accounts.new.link_text}
              </MenuButton>
            )}
            <MenuList>
              {links.accounts.new.products.map((product) => {
                const type = 'education';

                return (
                  <MenuItem px={3} py={2} key={product.title}>
                    <Flex as={NextLink} href={product.link} alignItems="center">
                      <SanityIcon
                        {...getIconByType(type)}
                        color={getIconColorByType(type)?.icon}
                        size="lg"
                        mr={3}
                      />
                      <Text fontWeight="semibold">{product.title}</Text>
                    </Flex>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </VStack>
      </Box>
      <Box mt={[0, null, 8]}>
        {links.menu.map((link) => (
          <Box mt={[8, null, 0]} key={link.label}>
            <Hide above="md">
              <Label>{link.label}</Label>
            </Hide>
            <VStack spacing={2}>
              {link.menu_item_links.map((item) => (
                <SidebarMenuItem
                  key={item.text}
                  name={item.text}
                  onClick={item.fn ? getFunction(item.fn) : undefined}
                  {...(item.link ? linkProps(item.link, router.asPath) : {})}
                  icon={item.icon}
                  subtle
                />
              ))}
            </VStack>
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default SidebarMenu;
