import { Flex } from '@chakra-ui/react';

import UserAvatar from '@components/UserAvatar';

import { Logo } from './Header';

type SidebarContainerProps = {
  children: React.ReactNode;
};

const SidebarContainer: React.FC<SidebarContainerProps> = ({ children }) => {
  return (
    <Flex
      direction="column"
      h="100vh"
      w="full"
      bg="white"
      p={6}
      position="sticky"
      top={0}
      overflowX="hidden"
      overflowY="auto"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Logo />
        <UserAvatar />
      </Flex>
      {children}
    </Flex>
  );
};

export default SidebarContainer;
