import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Link,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import logoLight from '@assets/images/raise-logo-light.svg';

type MobileDrawerProps = {
  disclosure: UseDisclosureReturn;
  children: React.ReactNode;
};

const MobileDrawer: React.FC<MobileDrawerProps> = ({
  disclosure,
  children,
}) => {
  return (
    <Drawer {...disclosure} placement="left" isFullHeight>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Link as={NextLink} href="/app" variant="unstyled">
            <Image src={logoLight.src} h={8} my={1} alt="Raise Financial" />
          </Link>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileDrawer;
