import {
  Box,
  Grid,
  Hide,
  Show,
  type UseDisclosureReturn,
} from '@chakra-ui/react';

import type { CommonDocument } from '@raise/sanity';

import Menu from './Menu';
import MobileDrawer from './MobileDrawer';
import MobileHeader from './MobileHeader';
import SidebarContainer from './SidebarContainer';

type SidebarProps = {
  links: CommonDocument['app'];
  children: React.ReactNode;
  disclosure: UseDisclosureReturn;
};

const Sidebar: React.FC<SidebarProps> = ({ links, children, disclosure }) => {
  return (
    <>
      <Hide above="md">
        <MobileDrawer disclosure={disclosure}>
          <Menu links={links} />
        </MobileDrawer>
        <MobileHeader disclosure={disclosure} />
        <Box bg="gray.50" px={[4, 8]} py={[6, 10]}>
          {children}
        </Box>
      </Hide>
      <Show above="md">
        <Grid templateColumns="300px 1fr">
          <SidebarContainer>
            <Menu links={links} mt={6} />
          </SidebarContainer>
          <Box p={[null, null, 8, 12, 16]} bg="gray.50" overflowX="hidden">
            {children}
          </Box>
        </Grid>
      </Show>
    </>
  );
};

export default Sidebar;
